.error {
    color: red;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
  }



  body::-webkit-scrollbar {
    width: 0;
  }
  
  body::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: none;
  }
  
    